import React, { useEffect, useState } from 'react';
import axios from 'axios';

function AuthCallback() {
  const [message, setMessage] = useState('Loading...');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
          const response = await axios.post(process.env.REACT_APP_BACKEND_URI+'/auth/exchange-code', {
            code: code
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200) {
            localStorage.setItem('googleCalendarToken', response.data.access_token);
            localStorage.setItem('googleCalendarRefreshToken', response.data.refresh_token);
            setMessage('Redirecting...');
            window.location.href = '/home';
          }
        } else {
          console.error('Authorization code not found.');
        }
      } catch (error) {
        console.error('Error during authorization:', error);
        setMessage(
          <div>
            <h1>Error during login, please try again</h1>
            <button onClick={() => window.location.href = '/'}>Go to Login</button>
          </div>
        );
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {message}
    </div>
  );
}

export default AuthCallback;
import React, {useState, useEffect} from "react";
import "../css/SavingGoal.css"
import ProgressBar from 'react-bootstrap/ProgressBar';
import Stack from 'react-bootstrap/Stack'
import {Button, Modal, Form} from 'react-bootstrap'
import Icon from '@mdi/react';
import { mdiPlus, mdiSquareEditOutline, mdiTrashCanOutline} from '@mdi/js';
import toast, { Toaster } from 'react-hot-toast';
import {jwtDecode} from "jwt-decode";

function SavingGoal(props) {

    const [loading, setLoading] = useState(true)  
    const [dataGet, setDataGet] = useState()
    const [showModal, setShowModal] = useState(false); 
    const [mode, setMode] = useState(null);
    const [amount, setAmount] = useState('');
    const [editId, setEditId] = useState('')

    const token = localStorage.getItem('token')
    const decodedToken = jwtDecode(token);

//Získání dat
    useEffect(() => {
        fetchData(process.env.REACT_APP_BACKEND_URI+`/savingGoal/get?userId=${decodedToken.id}`).then((data) => {
            setDataGet(data)
            });
    }, [props.now]);

    const fetchData = async (url) => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          });
    
          if (!response.ok) {
            throw new Error('Chyba při získávání dat');
          }
    
          const responseData = await response.json();
          if (Array.isArray(responseData.userSavingGoal)) {
            const updatedData = responseData.userSavingGoal.map(({ _id, goal, saved }) => ({ _id, goal, saved }));
            const simplifiedResponse = updatedData.length > 0 ? updatedData[0] : {};
            setLoading(false) 
            return simplifiedResponse;
          } else {
            return {};
        }
        } catch (error) {
          console.error('Chyba při získávání dat:', error);
          setLoading(false)
          return [];
        }
      };

      const closeModal = () => {
        setShowModal(false);
        setAmount(0)
      };
      const openModal = () => {
        setShowModal(true);
      };

//Vytvoření nového SavingGoalu
      const createNewPlan = async () => {
        try {
          const dToIn = {
            goal: amount,
            userId: decodedToken.id,
          };
          
          const response = await fetch(process.env.REACT_APP_BACKEND_URI+'/savingGoal/create', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn),
          });
          
          if (response.ok) {
            fetchData(process.env.REACT_APP_BACKEND_URI+`/savingGoal/get?userId=${decodedToken.id}`).then((data) => {
              setDataGet(data)
            });
            closeModal();
            setMode(null);
            toast.success('Successfully added!')
          } else {
            toast.error("This didn't work.")
            throw new Error('Chyba při aktualizaci goalu');
          }
        } catch (error) {
          toast.error("This didn't work.")
          console.error('Chyba při aktualizaci goalu:', error);
        }
      };

// Editace SavingGoalu
      const updatePlan = async () => {
        try {
            const dToIn = {
              newGoal: amount,
              _id: editId,
            };
        
            const response = await fetch(process.env.REACT_APP_BACKEND_URI+'/savingGoal/edit', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(dToIn),
            });
      
        
            if (response.ok) {
              fetchData(process.env.REACT_APP_BACKEND_URI+`/savingGoal/get?userId=${decodedToken.id}`).then((data) => {
                setDataGet(data)
              });
              closeModal();
              setMode(null);
              toast.success('Successfully changed!')
            } else {
              toast.error("This didn't work.");
              throw new Error('Chyba při aktualizaci goalu');
            }
          } catch (error) {
              toast.error("This didn't work.");
            console.error('Chyba při aktualizaci goalu:', error);
          }
      };

// Smazání SavingGoalu
      const deleteItem = async (idToDelete) => {
        try {
          const url= process.env.REACT_APP_BACKEND_URI+`/savingGoal/delete?id=${idToDelete}`;
      
          const response = await fetch(url, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          });
      
          if (response.ok) {
            fetchData(process.env.REACT_APP_BACKEND_URI+`/savingGoal/get?userId=${decodedToken.id}`).then((data) => {
                setDataGet(data)
            });
              toast.success('Successfully deleted!')
          } else {
            toast.error("This didn't work.")
            throw new Error('Chyba při mazání transakce');
          }
        } catch (error) {
            toast.error("This didn't work.")
          console.error('Chyba při mazání transakce:', error);
        }
      };


    return (
      <div className="SavingGoal">
          <Toaster position="top-center" reverseOrder={false}/>
            {loading ? (<></>) : (
            <>
                {dataGet.goal > 0 ? (<>
                    <Stack direction="horizontal">
                        <h1>Saving goal - {dataGet.goal.toLocaleString('cs-CZ')} €</h1>
                        <Button variant="primary" className="p-2 ms-auto" onClick={() => {openModal(); setMode("edit"); setEditId(dataGet._id); setAmount(0)}}><Icon path={mdiSquareEditOutline} size={1} style={{margin:"2px 2px"}}/></Button>
                        <Button variant="danger" className="p-2" style={{margin: "0 5px"}} onClick={() => deleteItem(dataGet._id)}><Icon path={mdiTrashCanOutline} size={1} style={{margin:"2px 2px"}}/></Button>
                    </Stack>
                    <ProgressBar animated now={(dataGet.saved / dataGet.goal) * 100} label={`${((dataGet.saved / dataGet.goal) * 100).toFixed(2)}%`}/>
                    <p style={{fontWeight: "bold"}}><span style={{color:"green"}}>Saved {dataGet.saved.toLocaleString('cs-CZ')} € </span><span style={{fontWeight: "400"}}>| Remaining {dataGet.goal - dataGet.saved} €</span></p>
                </>) : (
                    <Stack direction="horizontal">
                        <h1>Saving goal - {dataGet.goal} €</h1>
                        <Button variant="success" className="p-2 ms-auto" onClick={() => {openModal(); setMode("add"); }}><Icon path={mdiPlus} size={1} style={{margin:"2px 2px"}}/></Button>
                    </Stack>
                )}

            </>)}
            <Modal show={showModal} onHide={() => { closeModal() }}>
            <Modal.Header>
                <Modal.Title>
                    {mode === "add" ? (<>Create new plan</>):(<>Edit saving plan</>)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Saving goal - €</Form.Label>
                        <Form.Control placeholer="Description" onChange={(e) => setAmount(e.target.value)}></Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                {mode === "add" ? (
                    <Button variant="primary" onClick={createNewPlan} disabled={(isNaN(amount) || amount <= 0)}>Create</Button>
                ):(
                    <Button variant="primary" onClick={() => {updatePlan(); setAmount(0)}} disabled={(isNaN(amount) || amount <= 0 )}>Update</Button>
                )}
            </Modal.Footer>
        </Modal>
        </div>
    )
}

export default SavingGoal
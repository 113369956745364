import React, { useState, useEffect } from "react"
import "../css/Transactions.css"
import Icon from '@mdi/react';
import { mdiCreditCardPlusOutline, mdiSquareEditOutline, mdiTrashCanOutline} from '@mdi/js';
import {Table, Button, Modal, Form, Stack} from "react-bootstrap"
import toast, { Toaster } from 'react-hot-toast';
import * as mdiIcons from "@mdi/js";
import {jwtDecode} from "jwt-decode";


const Transactions = (props) => {
  const [transactionData, setTransactionData] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [mode, setMode] = useState(null);
  const [editAmount, setEditAmount] = useState('');
  const [editCategory, setEditCategory] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editDate, setEditDate] = useState('')
  const [selectedDate, setSelectedDate] = useState('');
  const [editId, setEditId] = useState('')
  const token = localStorage.getItem('token')
  const decodedToken = jwtDecode(token);

//Získání dat
  useEffect(() => {
    fetchData(process.env.REACT_APP_BACKEND_URI+`/transaction/list?userId=${decodedToken.id}`).then((data) => {
      setTransactionData(data.data);
    });

    fetchCategories(process.env.REACT_APP_BACKEND_URI+'/category/list').then((categories) => {
      setCategoryData(categories);
    });
  }, [props.now]); 

  //Získání transakcí
  const fetchData = async (url) => {
    try {
      const response = await fetch(url, { 
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Chyba při získávání dat');
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Chyba při získávání dat:', error);
      return [];
    }
  };

  //Získání kategorií
  const fetchCategories = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Chyba při získávání kategorií');
      }

      const responseData = await response.json();
      return responseData.types;
    } catch (error) {
      console.error('Chyba při získávání kategorií:', error);
      return [];
    }
  };

//Modalní okno
  const closeEditModal = () => {
    setShowEditModal(false);
    setMode(null);
  };

  const openEditModal = (item) => {
    setShowEditModal(true);
    setSelectedDate(null)
  };

//Smazání transakce
  const deleteItem = async (id) => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URI+`/transaction/delete?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.ok) {
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URI}/transaction/list?userId=${decodedToken.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

          }
        }).then(res => res.json());
  
        setTransactionData(data.data);
        props.rerender()
        toast.success('Successfully deleted!');
      } else {
        toast.error("This didn't work.");
        throw new Error('Chyba při mazání transakce');
      }
    } catch (error) {
      toast.error("This didn't work.");
      console.error('Chyba při mazání transakce:', error);
    }
  };
//Výpočet volných prostředků 
  const freeMoney = () => { 
    if (!transactionData || transactionData.length === 0) {
      return 0;
    }
    const result = transactionData.reduce((now, item) => {
      return item.category === 'Income' ? now + item.amount : now - item.amount;
    }, 0);

    return result;
  };

  const totalAmount = freeMoney();

//Vytvoření transakce
  const createTransaction = async () => {
    try {
      const dToIn = {
        amount: editAmount,
        category: editCategory,
        description: editDescription,
        userId: decodedToken.id,
        date: selectedDate === null ? (new Date()) : (selectedDate)
      };
  
      const response = await fetch(process.env.REACT_APP_BACKEND_URI+'/transaction/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dToIn),
      });
  
      if (response.ok) {
        fetchData(process.env.REACT_APP_BACKEND_URI+`/transaction/list?userId=${decodedToken.id}`).then((data) => {
          setTransactionData(data.data);
        });
        closeEditModal();
        setMode(null);
        toast.success('Successfully added!')
        props.rerender()
      } else {
        toast.error("This didn't work.")
        throw new Error('Chyba při aktualizaci transakce');
      }
    } catch (error) {
        toast.error("This didn't work.")
      console.error('Chyba při aktualizaci transakce:', error);
    }
  };

//Editace transakce  
  const updateTransaction = async () => {
    try {
      const dToIn = {
        amount: editAmount,
        category: editCategory,
        description: editDescription,
        id: editId,
        userId: decodedToken.id,
        date: selectedDate === null ? (editDate) : (selectedDate)
      };

  
      const response = await fetch(process.env.REACT_APP_BACKEND_URI+'/transaction/edit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dToIn),
      });

  
      if (response.ok) {
        fetchData(process.env.REACT_APP_BACKEND_URI+`/transaction/list?userId=${decodedToken.id}`).then((data) => {
          setTransactionData(data.data);
          props.rerender()
        });
        closeEditModal();
        setMode(null);
        toast.success('Successfully changed!')
      } else {
        toast.error("This didn't work.");
        throw new Error('Chyba při aktualizaci transakce');
      }
    } catch (error) {
        toast.error("This didn't work.");
      console.error('Chyba při aktualizaci transakce:', error);
    }
  }

//Pomocné pro datum
  const handleDateChange = (e) => {
    const selectedValue = e.target.value
    const selectedISODate = `${selectedValue}T00:00:00.000Z`
    setSelectedDate(selectedISODate);
  };


  return (
    <div className="Transactions"> 
      <Toaster position="top-center" reverseOrder={false}/>
        <Stack direction="horizontal">
          <h1>This month</h1>
          <h2 className=" ms-auto">Useable finances: <span style={{color: totalAmount >= 0 ? ("green") : ("red")}}>{totalAmount.toLocaleString('cs-CZ')} €</span></h2>
        </Stack>
        <Table className="Table flex-wrap" striped hover>
          <thead>
            <tr className="tHead">
              <th><h4>Amount</h4></th>
              <th><h4>Category</h4></th>
              <th><h4>Description</h4></th>
              <th><h4>Date</h4></th>
              <th className="Actions"><Button variant="success" onClick={() => {openEditModal(); setMode("add"); setEditAmount(0); setEditCategory("Other"); setEditDescription("<No description>")}}><Icon path={mdiCreditCardPlusOutline} size={1} /></Button ></th>
            </tr>
          </thead>
          <tbody>
            {transactionData && transactionData.map((item) => {
              const targetCategory = categoryData.find((category) => category.name === item.category);
                const categoryIconName = targetCategory ? targetCategory.icon: null;
                const IconComponent = categoryIconName ? mdiIcons[categoryIconName] : null;
                const categoryColor = targetCategory ? targetCategory.color : null;

                return (
                  <tr key={item._id}>
                    {item.category === "Income" ? (
                      <td style={{ color: "green" }}>+{item.amount.toLocaleString('cs-CZ')} €</td>
                    ) : (
                      <td style={{ color: "red" }}>- {item.amount.toLocaleString('cs-CZ')} €</td>
                    )}
                    <td>  
                      {IconComponent && categoryColor && (
                        <><Icon path={IconComponent} size={1} color={categoryColor} /> {item.category}</>
                      )}
                    </td>
                    <td>{item.description}</td>
                    <td>{new Date(item.date).toLocaleDateString()}</td>
                    <td className="Actions">
                      {item.category == "SavingGoal" ? null : <Button className="Button" variant="primary" onClick={() => {openEditModal(item); setMode("edit"); setEditId(item._id); setEditDate(item.date); setEditAmount(item.amount); setEditCategory(item.category); setEditDescription(item.description)}}><Icon path={mdiSquareEditOutline} size={1} /></Button>}
                      <Button className="Button" variant="danger" onClick={() => deleteItem(item._id)}><Icon path={mdiTrashCanOutline} size={1} /></Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        
        <Modal show={showEditModal} onHide={() => { closeEditModal(); setMode(null); }}>
            <Modal.Header>
                <Modal.Title>
                    {mode === "add" ? (<>Add new transaction</>):(<>Edit transaction</>)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>{mode === "add" ? <span>* </span> : null }Amount - €</Form.Label>
                        {mode === "add" ? (<Form.Control placeholer="Description" onChange={(e) => setEditAmount(e.target.value)}></Form.Control>) : (<Form.Control placeholer="Description" value={editAmount} onChange={(e) => setEditAmount(e.target.value)}></Form.Control>)}
                        <Form.Label>Category</Form.Label>
                        {mode === "add" ? (
                        <Form.Select onChange={(e) => setEditCategory(e.target.value)}>
                          <option />
                          {categoryData.map((item) => (
                                <option value={item.name} key={item.id}> {item.name} </option>
                          ))}
                        </Form.Select>):(
                          <Form.Select value={editCategory} onChange={(e) => setEditCategory(e.target.value)}>
                          <option />
                          {categoryData.map((item) => (
                                <option value={item.name} key={item.id}> {item.name} </option>
                          ))}
                        </Form.Select>
                        )}
                        <Form.Label>Description</Form.Label>
                        {mode === "add" ? (<Form.Control placeholer="Description" onChange={(e) => setEditDescription(e.target.value)}></Form.Control>):(<Form.Control placeholer="Description" value={editDescription} onChange={(e) => setEditDescription(e.target.value)}></Form.Control>)}
                        <Form.Label>Date</Form.Label>
                        <input type="date" className="datum" onChange={handleDateChange}></input>
                    </Form.Group>
                </Form>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeEditModal}>Cancel</Button>
                {mode === "add" ? (
                    <Button variant="primary" onClick={createTransaction} disabled={(isNaN(editAmount) || editAmount <= 0 || editCategory === null || editCategory.trim() === '')}>Add</Button>
                ):(
                    <Button variant="primary" onClick={updateTransaction} disabled={(isNaN(editAmount) || editAmount <= 0 || editCategory === null || editCategory.trim() === '')}>Update</Button>
                )}
            </Modal.Footer>
        </Modal>
        </div>
  );
};

export default Transactions;


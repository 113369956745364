import React, { useState, useEffect } from "react";
import "../css/UpcomingPayments.css"
import { Table, Button, Modal, Form } from "react-bootstrap"
import Icon from '@mdi/react';
import { mdiCreditCardClockOutline, mdiCalendarImportOutline, mdiCheck, mdiTrashCanOutline } from '@mdi/js';
import toast from 'react-hot-toast';
import {jwtDecode} from "jwt-decode";

function UpcomingPayments(props) {

    const [paymentList, setPaymentList] = useState()
    const [loading, setLoading] = useState(true)
    const [categoryData, setCategoryData] = useState([]);

    const token = localStorage.getItem('token');
    const googleCalendarToken = localStorage.getItem('googleCalendarToken');
    const decodedToken = jwtDecode(token);

    const [showModal, setShowModal] = useState(false);
    const [amount, setAmount] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        fetchData(process.env.REACT_APP_BACKEND_URI+`/upcomingPayment/list?userId=${decodedToken.id}`).then((data) => {
          setPaymentList(data.data);
        })

        fetchCategories(process.env.REACT_APP_BACKEND_URI+'/category/list').then((categories) => {
            setCategoryData(categories);
          });
          
      }, [props.now]);
    
      const fetchData = async (url) => {
        try {
          const response = await fetch(url, { 
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
          
            },
          });
    
          if (!response.ok) {
            setLoading(false)
            throw new Error('Chyba při získávání dat');
          }
    
          const responseData = await response.json();
          setLoading(false)
          return responseData;
        } catch (error) {
          console.error('Chyba při získávání dat:', error);
          setLoading(false)
          return [];
        }
      };
      const fetchCategories = async (url) => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!response.ok) {
            throw new Error('Chyba při získávání kategorií');
          }
    
          const responseData = await response.json();
          return responseData.types;
        } catch (error) {
          console.error('Chyba při získávání kategorií:', error);
          return [];
        }
      };

      const closeModal = () => {
        setShowModal(false);
      };
    
      const openModal = () => {
        setShowModal(true);
        setSelectedDate(null)
      };

      const createUpcomingPayment = async () => {
        try {
          const dToIn = {
            amount: amount,
            category: category,
            description: description,
            userId: decodedToken.id,
            paid: false,
            date: selectedDate === null ? (new Date()) : (selectedDate)
          };

      
          const response = await fetch(process.env.REACT_APP_BACKEND_URI+'/upcomingPayment/create', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn),
          });
      
          if (response.ok) {
            fetchData(process.env.REACT_APP_BACKEND_URI+`/upcomingPayment/list?userId=${decodedToken.id}`).then((data) => {
                setPaymentList(data.data);
            });
            closeModal();
            toast.success('Successfully added!')
          } else {
            toast.error("This didn't work.")
            throw new Error('Chyba při aktualizaci transakce');
          }
        } catch (error) {
            toast.error("This didn't work.")
          console.error('Chyba při aktualizaci transakce:', error);
        }
      };

      const deleteUpcomingPayment = async (idToDelete) => {
        try {
          const url= process.env.REACT_APP_BACKEND_URI+`/upcomingPayment/delete?id=${idToDelete}`;
      
          const response = await fetch(url, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          });
    
      
          if (response.ok) {
            fetchData(process.env.REACT_APP_BACKEND_URI+`/upcomingPayment/list?userId=${decodedToken.id}`).then((data) => {
                setPaymentList(data.data);
            });
              toast.success('Successfully deleted!')
          } else {
            toast.error("This didn't work.")
            throw new Error('Chyba při mazání transakce');
          }
        } catch (error) {
            toast.error("This didn't work.")
          console.error('Chyba při mazání transakce:', error);
        }
      };

      const paidUpcomingPayment = async (id) => {
        try {
          const url= process.env.REACT_APP_BACKEND_URI+`/upcomingPayment/setAsPaid`;
          const dToIn = {
            transId: id,
          };
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn),
          });

          if (response.ok) {
            fetchData(process.env.REACT_APP_BACKEND_URI+`/upcomingPayment/list?userId=${decodedToken.id}`).then((data) => {
                setPaymentList(data.data);
                props.rerender()
            });
              toast.success('Successfully !')
          } else {
            toast.error("This didn't work.")
            throw new Error('Chyba při mazání transakce');
          }
        } catch (error) {
            toast.error("This didn't work.")
          console.error('Chyba při mazání transakce:', error);
        }
      };

      const googleCalendarSync = async (item) => {
        try {
          const url= process.env.REACT_APP_BACKEND_URI+`/calendar/create`;
          const dToIn = {
            summary: "Financio - upcoming payment alert",
            description: item.description,
            date: item.date
          };
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${googleCalendarToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn),
          });

          if (response.ok) {
              toast.success('Successfully sended to google!')
          } else {
            toast.error("This didn't work.");
            throw new Error('Chyba při přidání do G-kalendáře');
          }
        } catch (error) {
          console.error('Chyba při přidání do G-kalendáře:', error);
        }
      };
    

      const handleDateChange = (e) => {
        const selectedValue = e.target.value
        const selectedISODate = `${selectedValue}T00:00:00.000Z`
        setSelectedDate(selectedISODate);
      };


    return(
        <div className="Payments">
            <h1>Upcoming payments</h1>
            <Table hover responzive="sm">
                <thead>
                    <tr>
                        <th>Due date</th>
                        <th>Amount</th>
                        <th>Category</th>
                        <th>Description</th>
                        <th style={{textAlign: "right"}}><Button variant="success" onClick={() => {openModal(); setAmount(0); setCategory(null); setDescription("<No description>")}}><Icon path={mdiCreditCardClockOutline} size={1} /></Button> </th>
                    </tr>
                </thead>
                <tbody>
            {loading ? (null):(<>
                {paymentList && paymentList.map((item) => {
                    return(
                    <tr key={item._id}>
                        <td>{new Date(item.date).toLocaleDateString()}</td>
                        <td>{item.amount} €</td>
                        <td>{item.category}</td>
                        <td>{item.description}</td>
                        <td className="col-2" style={{textAlign:"right"}}>
                           {item.paid ? (<Button variant="success" style={{marginRight:"2px"}}><Icon path={mdiCheck} size={1}/></Button>): (<Button variant="outline-success" style={{marginRight:"2px"}} onClick={() => paidUpcomingPayment(item._id)}><Icon path={mdiCheck} size={1}/></Button>)}
                           {decodedToken.isGoogleUser ? (<Button variant="outline-primary" style={{margin:"2px"}} onClick={() => googleCalendarSync(item)}><Icon path={mdiCalendarImportOutline} size={1} /></Button>) : null }
                            <Button variant="outline-danger" style={{marginLeft:"2px"}} onClick={() => deleteUpcomingPayment(item._id)}><Icon path={mdiTrashCanOutline} size={1}/></Button>    
                        </td>
                    </tr>)
                })}</>
            )}
                
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => { closeModal()}}>
            <Modal.Header>
                <Modal.Title>
                    Add new payment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>* Amount - €</Form.Label>
                        <Form.Control placeholer="Description" onChange={(e) => setAmount(e.target.value)}></Form.Control>
                        <Form.Label>* Category</Form.Label>
                        <Form.Select onChange={(e) => setCategory(e.target.value)}>
                            <option />
                            {categoryData.map((item) => (
                             item.name === "Income" || item.name === "SavingGoal"  ? null : (
                                 <option key={item.id} value={item.name}>
                                     {item.name}
                                </option>
                                )
                                ))}
                        </Form.Select>
                        <Form.Label>Description</Form.Label>
                        <Form.Control placeholer="Description" onChange={(e) => setDescription(e.target.value)}></Form.Control>
                        <Form.Label>Date</Form.Label>
                        <input type="date" className="datum" onChange={handleDateChange}></input>
                    </Form.Group>
                </Form>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                
                    <Button variant="primary" onClick={createUpcomingPayment} disabled={(isNaN(amount) || amount <= 0 || category === null)}>Create</Button>
                
            </Modal.Footer>
        </Modal>
        </div>
    )
}

export default UpcomingPayments 

import React, {useState, useEffect} from "react"
import Transactions from "../bricks/Transactions"
import Cashflow from "../bricks/Cashflow"
import SavingGoal from "../bricks/SavingGoal"
import Overview from "../bricks/Overview"
import UpcomingPayments from "../bricks/UpcomingPayments"
import Button from "react-bootstrap/Button"
import { Container, Row, Col, Stack, Modal} from "react-bootstrap"
import "../css/Home.css"


function Home() {


    const handleLogout = () => {
        // Remove items from local storage
        localStorage.removeItem('token');
        localStorage.removeItem('googleCalendarToken');
        localStorage.removeItem('googleCalendarRefreshToken');
        localStorage.removeItem('googleIdToken');
        localStorage.removeItem('loginTime');

        // Navigate to root URL
        window.location.href = '/';
    };

    const [showModal, setShowModal] = useState(false); 
    const [now, setNow] = useState(1)

    const closeModal = () => {
        setShowModal(false);
      };
      const openModal = () => {
        setShowModal(true);
      };

    const rerender = async () => {
        try {
            setNow (now +1)
        } catch (error) {
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(async () => {
          const token = localStorage.getItem('token');
          if (token) {
            const loginTime = new Date(localStorage.getItem('loginTime')); // convert from ISO string
            const now = new Date(); 
    
            if (now.getTime() - loginTime.getTime() > 50 * 60 * 1000) { // log out in 50 minutes
              handleLogout();
            }
          } else {
            console.log('No token found');
          }
        }, 60 * 1000); // 1 minute
    
        return () => clearInterval(intervalId); // Clear interval on component unmount
      }, []);

    return( 
        <div>
            <Stack direction="horizontal" className="Header-stack">
                <h1>Financ•io</h1>
                <Button variant="primary" className="p-2 ms-auto Header-button" onClick={openModal}>Logout</Button>
            </Stack>
            <Transactions now={now} rerender={rerender}/>
            <SavingGoal now={now} rerender={rerender}/>
            <Container fluid >
                <Row style={{margin: "0"}}>
                    <Col lg={4} xs={12} sm={12} md={12} xl={4} xxl={3}><Cashflow now={now} rerender={rerender}/></Col>
                    <Col lg={8} xs={12} sm={12} md={12} xl={8} xxl={9}><UpcomingPayments now={now} rerender={rerender}/></Col>
                </Row>
                <Row style={{margin: "0"}}>
                <Col lg={12} xs={12} sm={12} md={12} ><Overview now={now}/></Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={() => { closeModal() }}>
            <Modal.Header>
                <Modal.Title>
                    Logout
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you want to log out?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleLogout}>Logout</Button>
                <Button variant="primary" onClick={closeModal}>Stay logged in</Button>
            </Modal.Footer>
        </Modal>
        </div>
    )
}

export default Home;